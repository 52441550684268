<template>
    <div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <!-- 分类区 -->
        <div class="bg_box">
            <div class="ct_box">
                <Nav title="99" />
                <!-- 价格区间： -->
                <div class="type2 flex">
                    <p>价格区间：</p>
                    <ul class="flex ul2">
                        <li :class="MoneyType.title == '不限' ? 'active' : ''" @click="MoneyShow('不限', 0)">不限</li>
                        <li :class="MoneyType.title == item ? 'active' : ''" v-for="(item, index) in MoneyList"
                            :key="index" @click="MoneyShow(item, index)">
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="type3 flex">
                    <p>排序：</p>
                    <ul class="flex ul2">
                        <!-- <li  v-for="(item, index) in SortList" :key="index"
                        
                        ">{{ item }}</li> -->
                        <!-- 状态码：0:未选中 1:升序 2:降序-->
                        <li @click="StateShow('默认')" :class="SortType.title == '默认' ? 'active' : ''">
                            默认
                        </li>

                        <li @click="StateShow('价格', 'member_price')" :id="SortType.title != '默认' && SortType.title == '价格' && MoneyState == 1
        ? 'activeTrue'
        : SortType.title != '默认' && SortType.title == '价格' && MoneyState == 2
            ? 'activeFalse'
            : ''
        " :class="SortType.title == '价格' ? 'active' : ''">
                            价格
                        </li>
                    </ul>
                </div>
                <div class="type4 flex">
                    <p class="type_title_n">套餐名称：</p>
                    <el-input @keyup.enter.native="page = 1; ListFn()"  
                        v-model="mediaName" placeholder="请输入编号或名称"></el-input>
                    <button class="sf_but" type="primary" @click="page = 1;ListFn()">筛选</button>
                    <button class="sf_but2" type="primary" @click="page = 1;AllMediaTab()">
                        重置筛选
                    </button>
                    <collect :is_collect="is_collect" @collectShow="collectShow" />
                    <mediaWhiteList />
                </div>
            </div>
        </div>
        <el-table :cell-style="finalCellStyle" :header-cell-style="headerStyle" :data="tableData"
            style="width: 80%; margin:0 auto 20px auto;">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="center" inline class="demo-table-expand">
                        <el-form-item label="套餐明细:">
                            <div class="flex" style="flex-wrap: wrap;">
                                <a :href="item.case_url" target="_blank" class="order_ct" style="font-size: 1.2rem;"
                                    v-for="(item, index) in props.row.medium_list">{{
        item.title
    }}</a>
                            </div>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="套餐名称" prop="title">
            </el-table-column>
            <el-table-column label="价格">
                <template slot-scope="scope">
                    ￥{{ scope.row.price.toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column label="媒介总数" prop="num">
            </el-table-column>
            <el-table-column label="备注(仅供参考)">
                <template slot-scope="scope">
                    <td_remark :text="scope.row.des" />
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <button class="place_but" @click="handleEdit(1, scope.row)">立即下单</button>
                    <div class="flex collect_box" style="justify-content: center;">
                        <p v-show="scope.row.is_collect == 0" class="collect" @click="collectGet(scope.row)">收藏</p>
                        <p v-show="scope.row.is_collect == 1" class="collect2" @click="collectGet(scope.row)">已收藏</p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="paging_box">
            <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Nav from '../../components/MediaNav.vue'
export default {
    name: '',
    computed: {
        ...mapState(['Media', 'MediaNav', 'TypeItem', 'ActiveChild']),
    },
    components: {
        Nav
    },
    created() { },
    mounted() {
        this.fullscreenLoading = true
        setTimeout(() => {
            for (const i in this.$store.state.Media) {
                if (this.$store.state.Media[i].id == 99) {
                    this.MoneyList = this.$store.state.Media[i].price_range
                    this.SortList = this.$store.state.Media[i].order_list
                }
            }
            this.user_info = this.$util.getLocalStorage('user_info')
            this.ListFn()
            this.fullscreenLoading = false
        }, 200)

    },
    data() {
        return {
            user_info: {},
            // 价格区间
            MoneyType: {
                id: 0,
                title: '不限'
            },
            MoneyList: [],
            // 排序
            SortType: {
                id: 0,
                title: '默认'
            },
            SortList: [],
            MoneyState: 0, //价钱升序降序控制器
            MoveState: 0, //移动权重升序降序控制器
            /*-----------------媒体筛选------------------------*/
            mediaName: '', // 媒介名称,
            linkList: ['是', '否'], //可发链接列表
            link: '', //可发链接
            relation: '', //可发联系方式
            /*数据表格*/
            tableData: [],
            shopCar: [], //购物车结算
            totalMoney: undefined, //价格总和
            Allcheck: false,
            fullscreenLoading: false,
            total_page: 0, //分页总页数
            count: 0, //总条数
            page: 1,
            // 套餐子列表显示隐藏
            selectShow: true,
            selectTable: [],
            is_collect: 0,
        }
    },
    methods: {
        pageChange(pageVal) {
            this.page = pageVal
            this.ListFn()
        },
        headerStyle() {
            return {
                color: '#168ad9',
                fontSize: "14px",
                textAlign: "center",
            }
        },
        finalCellStyle(row) {
            let style = {
                textAlign: "center",
                fontSize: "14px"

            };
            return style;
        },
        StateShow(item, index) {
            /*
            每次点击时需要重置价格-电脑权重-移动权重的状态,在每个判断条件中需做重置
            */
            this.SortType = {
                id: index,
                title: item,
            }
            switch (item) {
                case '默认':
                    this.MoneyState = 0
                    this.SortType.id = 0
                    break

                case '价格':
                    this.MoveState = 0
                    if (this.MoneyState == 0) {
                        this.MoneyState = 1
                    } else if (this.MoneyState == 1) {
                        this.MoneyState = 2
                    } else {
                        this.MoneyState = 1
                    }
                    break
                default:
                    break
            }
            this.page = this.pageChangeOne()
            this.ListFn()
        },
        selectTab(item) {
            this.selectShow = !this.selectShow
            this.selectTable = item
        },
        // 清空购物车
        empty() {
            this.$confirm('是否清空已选媒体?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.fullscreenLoading = true
                    // setTimeout(() => {
                    this.CarList = []
                    this.shopShow = false
                    // this.toggleSelection()
                    this.tableData.forEach((item, index) => {
                        if (item.check) {
                            return item.check = false
                        }
                    })
                    this.fullscreenLoading = false
                    // }, 500)
                    this.$message({
                        type: 'success',
                        message: '已清空已选媒体',
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'warning',
                        message: '已删除',
                    })
                })
        },
        // 结算
        handleEdit(index, row) {
            this.shopCar.push(row)
            this.$store.commit('childTab', '选择套餐')
            this.$store.commit('shopPush', this.shopCar)
            this.$router.push('/user/SetMenu/fill?id=8')
        },
        // 价格区间切换
        MoneyShow(item, index) {
            this.MoneyType = {
                title: item,
                id: index ? parseInt(index) : 0
            }
            this.ListFn()
        },
        // 切换页
        currentTab(val) {
            this.page = val
            this.ListFn()
            this.TabCheck()
        },
        // 上一页
        prevClick() {
            this.page -= 1
            this.ListFn()
            this.TabCheck()
        },
        // 下一页
        // next-click
        nextClick() {
            this.page += 1
            this.ListFn()
            this.TabCheck()
        },
        // 是否切换收藏列表
        collectShow(id) {
            this.is_collect = id
            this.ListFn()
        },
        // 获取列表数据
        ListFn() {
            let data = {}
            data.page = this.page
            data.limit = 20
            if (this.title !== '') {
                data.title = this.title
            }
            if (this.MoneyType.id != 0) {
                data.price_id = this.MoneyType.id
            }
            if (this.SortType.id != 0) {
                data.sort = 'price'
            }
            if (this.MoneyState == 1) {
                data.order = 'asc'
            } else if (this.MoneyState == 2) {
                data.order = 'desc'
            }

            data.collect = this.collect
            if (this.mediaName !== '') {
                data.title = this.mediaName
            }
            if (this.link != '' && this.link == '是') {
                data.is_pub_url = 1
            } else if (this.link !== '' && this.link == '否') {
                data.is_pub_url = 0
            }
            if (this.relation !== '' && this.relation == '是') {
                data.is_pub_contact = 1
            } else if (this.relation !== '' && this.relation == '否') {
                data.is_pub_contact = 0
            }
            if (this.is_collect) {
                data.collect = this.is_collect
            }
            this.fullscreenLoading = true
            this.curlGet('/api/medium/package', data).then(res => {
                if (res.data.code) {
                    this.tableData = res.data.data.list
                    this.total_page = res.data.data.total_page
                    this.count = res.data.data.count
                    this.tableData.forEach((item, index) => {
                        this.$set(this.tableData[index], 'select', false)
                        this.tableData[index].price = parseInt(this.tableData[index].price)
                    })
                    this.fullscreenLoading = false
                } else {
                    this.fullscreenLoading = false

                }
            })
        },
        // 重置选择
        AllMediaTab() {
            this.mediaName = ''
            this.MoneyType = {
                id: 0,
                title: '不限'
            }
            this.SortType = {
                id: 0,
                title: '默认'
            }

            this.ListFn()
        },
        // 收藏
        collectGet(item) {
            if (!this.user_info) {
                this.$message({
                    type: 'warning',
                    message: '请登录',
                    offset: 90,
                })
            } else {
                this.curlGet('/api/medium/collect', {
                    mid: item.id,
                    style: 2
                }).then(res => {
                    if (res.data.code) {
                        this.tableData.forEach((elem, index) => {
                            if (item.id == elem.id) {
                                this.tableData[index].is_collect = !this.tableData[index].is_collect
                                if (this.tableData[index].is_collect) {
                                    return this.$message({
                                        message: '收藏成功',
                                        type: 'success',
                                        offset: 80,
                                    })
                                } else {
                                    this.$message({
                                        message: '已取消收藏',
                                        type: 'warning',
                                        offset: 80,
                                    })
                                }
                            }
                        })

                        // this.getList()
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../scss/Media';

::v-deep .el-form-item__label {
    font-weight: bold;
}
</style>